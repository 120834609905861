import AddressList from "./components/AddressList";
import FeedBack from "./components/FeedBack";
import "./style.scss";

export default function Contact() {
    return (
        <div className="contact-page bg-black">
            <AddressList className="w-screen pt-20 pb-20 flex justify-center items-center" />
            <FeedBack />
        </div>
    );
}
