import { list } from "../apis/product";

const GALLERY_TYPE = "1";

export default class ProductService {
    async getList(page = 1) {
        const res = await list(page);

        if (res?.data?.data) {
            res.data.list = res.data.data;
        }
        return res.data || null;
    }

    async getGallerys() {
        const res = await list(1, 100, GALLERY_TYPE);

        if (res?.data?.data) {
            res.data.list = res.data.data;
        }
        return res.data || null;
    }
}
