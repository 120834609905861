import { useCallback, useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { getAppResource } from "../../../apis/document";

export default function AddressList({ className }) {
    const [addressList, setAddressList] = useState([]);
    const fetchAddressList = useCallback(async () => {
        try {
            const addressList =
                (await getAppResource({
                    docKey: "address",
                    type: "json",
                    source: "contact",
                })) || [];
            setAddressList(addressList);
        } catch (error) {}
    }, []);
    useEffect(() => {
        fetchAddressList();
        // eslint-disable-next-line
    }, []);
    return (
        <div className={`flex justify-center w-full flex-wrap ${className}`}>
            {addressList?.map((address, index) => {
                return <AddressItem key={index} {...address} />;
            })}
        </div>
    );
}

function AddressItem({
    city = "",
    street = "",
    address = "",
    tel = "",
    email = "",
    link = "",
}) {
    return (
        <div className="text-xl p-20 address-item text-center">
            <p className="font-bold text-xl ">EMAIL</p>
            {email && <a href={"mailto:" + email}>{email}</a>}
            {tel && (
                <>
                    <p className="font-bold text-xl mt-2">TEL</p>
                    <p className="">{tel}</p>
                </>
            )}
            <p className="font-bold text-xl mt-2">ADDRESS</p>
            <p>{address}</p>
            {street && (
                <p>
                    {street} {city}
                </p>
            )}
            {link && (
                <p className="mt-10 ">
                    <a
                        target="_blank"
                        href={link}
                        rel="noreferrer"
                        className="flex block justify-between"
                    >
                        <span>View Map</span>
                        <AiOutlineArrowRight className="arrow" />
                    </a>
                </p>
            )}

            <div className="light-line mt-3"></div>
        </div>
    );
}
