import backgroundImg from "../../shared/assets/images/bg2.jpg";
import "./style.scss";

export default function About() {
    return (
        <div>
            <div className="about-page bg-black flex items-center">
                <div className="about-text ml-auto mr-auto">
                    <h2 className="text-2xl font-bold text-center">
                        We are TIPTOP, we pursue tiptop visual art.
                    </h2>

                    <p className="mt-10">
                        Tiptop is a Shanghai based high end architecture
                        visualization studio. We believe great visual art can
                        motivate people's fantastic inspiration of their life
                        and present them the future where they want to live.
                    </p>

                    <p className="mt-4">
                        At Tiptop, we see our role as a keen observer of life
                        and the architect of impactful narratives. We discover
                        the subtle beauty in life, listening to the unique story
                        of each building. We strive to be an curator of
                        imagination, connecting the complex reality with the
                        imagination of designers.
                    </p>

                    <p className="mt-4">
                        Driven by a team of industry-leading creatives, we
                        operate at the intersection of innovation and
                        craftsmanship. Our main services include still images,
                        fly-through, multimedia and interative products. Our
                        work transcends traditional boundaries, blending art
                        with technology to deliver bespoke solutions tailored to
                        our clients' needs.
                    </p>
                    <p className="mt-4">
                        To learn more, click on the link that’s most suitable
                        for you. Let's take you to an extraordinary visual tour.
                    </p>
                </div>
            </div>
        </div>
    );
}
