import axios from "../shared/utils/axios";
export async function list(page = 1, pageSize = 15, type = "0") {
    const res = await axios.get("/m/product/list", {
        params: {
            page: page - 1,
            size: pageSize,
            type,
        },
    });
    return res?.data ? res.data : null;
}
