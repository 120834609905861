import { useState, useEffect } from "react";
import { getAppResource } from "../../../apis/document";

export default function Copyright() {
    const [content, setContent] = useState("");
    const getContent = async () => {
        const doc = await getAppResource({
            docKey: "copyright",
            source: "home",
            type: "html",
        });
        setContent(doc || "");
    };
    useEffect(() => {
        getContent();
    // eslint-disable-next-line
    }, []);

    return (
        <div
            className="p-10 text-center text-sm"
            dangerouslySetInnerHTML={{ __html: content }}
        ></div>
    );
}
