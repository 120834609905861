import React from "react";

import "./style.scss";

export default function Waterfall({ domRef, datasource, className }) {
    /** 翻页相关 */
    return (
        <div ref={domRef} className={`flex waterfall ${className}`}>
            {datasource?.map(({ list }, index) => {
                return (
                    <div key={index} className="flex-auto shrink grow w-full">
                        {list}
                    </div>
                );
            })}
        </div>
    );
}
