import axios from "../shared/utils/axios";
export async function contact() {
    const res = await axios.get("/m/contact");
    return res?.data ? res.data : null;
}
export async function feedback({ nickName, email, message }) {
    try {
        const res = await axios.post("/m/contact/feedback", {
            nickName,
            email,
            message,
        });
        return res?.data ? res.data : null;
    } catch (error) {
        return error?.response?.data;
    }
}
