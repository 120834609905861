import { useState, useCallback, useRef, useEffect } from "react";
import parseJson from "../../../shared/utils/parseJson";
import { createProductFullPath } from "../../../shared/utils/createProductFullPath";
import { WaterfallProduct } from "../components/Waterfall/WaterfallProduct";

let bodyElement;

export default function usePhotoViewer({ list, column, total, onNextPage }) {
    // 当前查看的图片
    const topicDomRef = useRef();
    const wrapRef = useRef();
    const [curImg, setCurImg] = useState(null);
    const [curVideo, setCurVideo] = useState(null);
    const [curIndex, setCurIndex] = useState(-1);
    const [isShowPhotoView, setIsShowPhotoView] = useState(false);
    const posListRef = useRef([]);
    const topicsRef = useRef([]);

    posListRef.current = [];

    const columnWrap = [];
    // 初始化列
    for (var i = 0; i < column; i++) {
        columnWrap[i] = { list: [], height: 0 };
    }

    const showPhotoView = useCallback((index) => {
        // 获取对应的DOM
        setCurIndex(index);
    }, []);

    const setCurrentVideoByIndex = useCallback(
        (index) => {
            const item = list[index];
            if (item) {
                let { id, videoKey } = item;
                let source = null;
                if (videoKey) {
                    source = {
                        id,
                        index,
                        sourceVideo: createProductFullPath(videoKey),
                    };
                }
                setCurVideo(source);
            }
        },
        [list, setCurVideo],
    );

    const createProducts = (
        {
            id,
            description = "",
            pictureKey: big,
            videoKey: video,
            thumbnailUrl = "",
            ossBusiness = "",
        },
        index,
    ) => {
        const { width = 0, height = 0 } = parseJson(ossBusiness) || {};
        const sourceImg = createProductFullPath(big);
        const sourceVideo = createProductFullPath(video);
        // 这里不需要缩略图了，但为了保留发动痕迹，先放在 data-src 上了
        const smallPicture = `${process.env.REACT_APP_OSS_URL}${
            thumbnailUrl || "/static/" + big
        }`;
        const onItemClick = (e) => {
            showPhotoView(index);
            let _video = {
                id,
                sourceVideo,
                index,
            };
            setCurVideo(video ? _video : null);
            e.stopPropagation();
            e.preventDefault();
        };

        // 找到瀑布流中列最小的一个
        let curWrapIndex = findMinIndex(columnWrap);
        const curWrap = columnWrap[curWrapIndex];
        const ratio = height / width || 1;

        // 计算图片在瀑布流中的顺序，用于点击时从瀑布流中查找
        posListRef.current.push([
            String(curWrapIndex),
            String(curWrap?.list.length),
        ]);

        if (index === 0) {
            topicsRef.current = [];
        }

        // 顶部左右俩护法
        if (index < 2) {
            return topicsRef.current.push(
                <WaterfallProduct
                    id={id}
                    description={description}
                    isShowPhotoView={isShowPhotoView}
                    onItemClick={onItemClick}
                    smallPicture={smallPicture}
                    width={width}
                    height={height}
                    sourceImg={sourceImg}
                    video={video}
                    sourceVideo={sourceVideo}
                />,
            );
        }

        curWrap.height += ratio;

        curWrap.list.push(
            <WaterfallProduct
                id={id}
                description={description}
                isShowPhotoView={isShowPhotoView}
                onItemClick={onItemClick}
                smallPicture={smallPicture}
                width={width}
                height={height}
                sourceImg={sourceImg}
                video={video}
                sourceVideo={sourceVideo}
            />,
        );
    };

    // 把图片分配到瀑布流中
    (list ?? []).forEach(createProducts);

    /** 翻页相关 */
    const nextHandle = useCallback(async () => {
        if (curIndex < total - 1) {
            let index = curIndex + 1;
            setCurIndex(index);
            setCurrentVideoByIndex(index);
        }
    }, [curIndex, total, setCurrentVideoByIndex]);

    const prevHandle = useCallback(() => {
        if (curIndex > 0) {
            let index = curIndex - 1;
            setCurIndex(index);
            setCurrentVideoByIndex(index);
        }
    }, [curIndex, setCurrentVideoByIndex]);

    const getImageByIndex = async (index) => {
        if (index < 0 || index >= total) {
            console.log("getImageByIndex block", index, total);
            return null;
        }

        if (index + 1 >= list.length) {
            console.log("getImageByIndex next", index, list.length);
            await onNextPage();
        }
        const column = posListRef.current[index][0];
        const row = posListRef.current[index][1];
        console.log("topicDomRef", topicDomRef.current);

        if (index < 2) {
            console.log("getImageByIndex topic", index);
            return topicDomRef.current.querySelectorAll(".topic-container")[
                index
            ].children[0].children[0];
        }
        console.log("getImageByIndex waterfall", index);

        return wrapRef.current?.children?.[column]?.children[row]?.children[0];
        // debugger;
        // return image;
    };

    const onHideStart = () => {
        bodyElement?.classList.remove("overflow-hidden");
        setIsShowPhotoView(false);
    };

    const onShowStart = () => {
        bodyElement?.classList.add("overflow-hidden");
        setIsShowPhotoView(true);
    };

    const onHideEnd = () => {
        setCurIndex(-1);
    };

    useEffect(() => {
        getImageByIndex(curIndex).then((image) => {
            console.log("getImageByIndex set image", image);
            setCurImg(image);
        });
        // eslint-disable-next-line
    }, [curIndex]);

    useEffect(() => {
        bodyElement = document.body;
        return () => {
            bodyElement = null;
        };
        // eslint-disable-next-line
    }, []);

    return {
        wrapRef,
        topicDomRef,
        topicsRef,
        curImg,
        curIndex,
        curVideo,
        isShowPhotoView,
        columnWrap,
        nextHandle,
        prevHandle,
        onHideStart,
        onShowStart,
        onHideEnd,
    };
}

// 找到数组中高度最小的
function findMinIndex(array) {
    let minIndex = 0;
    for (let i = 1; i < array.length; i++) {
        if (array[i].height < array[minIndex].height) {
            minIndex = i;
        }
    }
    return minIndex;
}
