import { useRef } from "react";

export function useThrottle(fn, delay) {
    const { current } = useRef({});
    function f(...args) {
        if (!current.timer) {
            current.timer = setTimeout(() => {
                delete current.timer;
            }, delay);
            fn(...args);
        }
    }
    return f;
}
