import { request } from "../shared/utils/axios";
import parseJson from "../shared/utils/parseJson";

const APP_RESOURCE_CACHE = {};

export async function getAppResource({
    docKey = "",
    source = "all",
    type = "text",
}) {
    const cacheKey = `${docKey}${source}${type}`;
    if (cacheKey && APP_RESOURCE_CACHE[cacheKey]) {
        return APP_RESOURCE_CACHE[cacheKey];
    }
    let { data = {} } = await request({
        type: "post",
        url: "document/getAppResource",
        data: { docKey, source, type },
    });
    let { content = "" } = data || {};

    switch (type) {
        case "json":
        case "array":
            content = parseJson(content);
            break;
        default:
            break;
    }
    if (cacheKey) {
        APP_RESOURCE_CACHE[cacheKey] = content;
    }
    return content;
}
