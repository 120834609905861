export default function parseJson(str) {
    if (typeof str === "string") {
        try {
            return JSON.parse(str);
        } catch (error) {
            console.log(error);
            return {};
        }
    }
    return str;
}

