import { useCallback, useEffect } from "react";

export default function useResize(fn) {
    const callback = useCallback(
        (e) => {
            fn({
                ww: document.body.clientWidth,
                wh: document.body.clientHeight,
            });
        },
        [fn],
    );
    useEffect(() => {
        callback();
        window.addEventListener("resize", callback);
        return () => {
            window.removeEventListener("resize", callback);
        };
    }, [callback]);
}
