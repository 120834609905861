import { useCallback, useState } from "react";
import Social from "./Social";
import { feedback } from "../../../../apis/contact";
import "./style.scss";

const initFormData = {
    nickName: "",
    email: "",
    message: "",
};
export default function FeedBack() {
    const [formData, _setFormData] = useState(initFormData);
    const setFormData = useCallback(
        (e) => {
            _setFormData({ ...formData, [e.target.name]: e.target.value });
        },
        [formData, _setFormData],
    );
    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const { nickName, email, message } = formData;
            if (!nickName) {
                alert("please input nickname");
                return;
            }
            if (!email) {
                alert("please input email");
                return;
            }
            if (!message) {
                alert("please input message");
                return;
            }
            const { code, msg, error } = await feedback(formData);
            if (code === 200) {
                alert("successful");
            } else {
                alert(error[0] || msg || "failed");
            }
            _setFormData(initFormData);
        },
        [formData,_setFormData],
    );
    return (
        <div className="footer text-center p-2 pt-6">
            <h2>SEND A MESSAGE</h2>
            <p>
            Let's take you to an extraordinary visual tour.
            </p>
            <form
                action=""
                onSubmit={onSubmit}
                className="mt-6 container mx-auto text-base"
            >
                <div className="flex justify-center">
                    <input
                        value={formData?.nickName}
                        className="outline-0 bg-transparent m-1 w-1/2"
                        name="nickName"
                        onChange={setFormData}
                        placeholder="nickname"
                    />
                    <input
                        value={formData?.email}
                        className="outline-0 bg-transparent m-1 w-1/2"
                        name="email"
                        onChange={setFormData}
                        placeholder="Email"
                    />
                </div>
                <div className="box-border p-1">
                    <textarea
                        value={formData?.message}
                        className=" outline-0 bg-transparent resize-none w-full  h-32"
                        placeholder="Message"
                        name="message"
                        onChange={setFormData}
                    ></textarea>
                </div>

                <button className="pl-10 pr-10 h-10 mt-2" type="submit">
                    SEND MESSAGE
                </button>
            </form>
            <Social className="mt-10" />
        </div>
    );
}
