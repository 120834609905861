import { useCallback, useRef, useEffect, useMemo, useState } from "react";
import { throttle, debounce } from "lodash";
import HomePoster from "./components/HomePoster";
import Waterfall from "./components/Waterfall";
import Copyright from "./components/Copyright";
import PhotoViewer from "./components/PhotoViewer";
import posterImg from "../../shared/assets/images/poster.jpg";
import SloganAsset from "../../shared/assets/images/logo_slogan.png";
import usePhotoViewer from "./hooks/usePhotoViewer";
import ProductService from "../../services/productService";
import {
    fetchHomeBg,
    fetchLogo,
    fetchSlogan,
    fetchWaterfallColumn,
} from "./heplers/apis";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.scss";
//Connect inspiration and create the ultimate future.
const Config = {
    poster: {
        image: posterImg,
        slogan: "",
    },
    DEFAULT_COLUMN_NUMBER: 3,
};

let bodyElement;

export default function Home() {
    const productService = useMemo(() => new ProductService(), []);
    const [gallerys, setGallerys] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(1);
    const [homeBg, setHomeBg] = useState();
    const [logo, setLogo] = useState();
    const [slogan, setSlogan] = useState("");
    const [column, setColumn] = useState(Config.DEFAULT_COLUMN_NUMBER);
    const columnNumberRef = useRef(Config.DEFAULT_COLUMN_NUMBER);

    const { poster } = Config;

    const getList = useCallback(async () => {
        const isEnd = imageList.length >= total;
        if (isLoading || isEnd) {
            return;
        }
        setIsLoading(true);
        setPage(page + 1);
        const res = await productService.getList(page);
        console.log(res);
        if (res?.list) {
            setImageList((imageList) => {
                const newList = [...imageList, ...res.list];
                setImageList(newList);
                setTotal(res.total);
            });
        }
        setIsLoading(false);
    }, [
        productService,
        page,
        setImageList,
        isLoading,
        total,
        imageList,
        setTotal,
    ]);

    const getGallerys = useCallback(async () => {
        setIsLoading(true);
        const res = await productService.getGallerys();
        if (res?.list) {
            setGallerys(res.list);
        }
        setIsLoading(false);
    }, [setIsLoading, setGallerys]);

    const getHomeBg = async () => {
        // TODO
        const res = await fetchHomeBg();
        console.log("TODO", res);
        setHomeBg(SloganAsset);
    };

    const getWaterfallColumn = async () => {
        const columnNumber = await fetchWaterfallColumn({
            defaultColumnNumber: Config.DEFAULT_COLUMN_NUMBER,
        });
        columnNumberRef.current = columnNumber;
    };

    const getLogo = async () => {
        const res = await fetchLogo();
        setLogo(res);
    };

    const getSlogan = async () => {
        const res = (await fetchSlogan()) || poster.slogan;
        setSlogan(res);
    };

    const onScroll = debounce((e) => {
        const html = document.getElementsByTagName("html")[0];
        let st = html.scrollTop;
        if (st >= html.scrollHeight - html.clientHeight - 80) {
            getList();
        }
    }, 100);

    useEffect(() => {
        bodyElement = document?.body;
        getGallerys();
        getWaterfallColumn().then(() => {
            getList();
        });
        getHomeBg();
        getLogo();
        getSlogan();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.addEventListener("scroll", onScroll, false);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    const onResize = throttle(
        useCallback(() => {
            const ww = window.innerWidth;
            const isSmall = ww < 750;
            isSmall && bodyElement?.classList?.add?.("small-screen");
            setColumn(isSmall ? 1 : columnNumberRef.current);
        }, [setColumn, columnNumberRef.current]),
        200,
    );

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [onResize]);

    const {
        wrapRef,
        topicDomRef,
        topicsRef,
        curImg,
        curIndex,
        curVideo,
        columnWrap,
        nextHandle,
        prevHandle,
        onHideStart,
        onShowStart,
        onHideEnd,
    } = usePhotoViewer({
        list: imageList,
        onNextPage: getList,
        column,
        total,
    });

    const [LeftImageTopic, RightImageTopic] = useMemo(
        () => topicsRef.current || [],
        [topicsRef.current],
    );

    return (
        <div className="home-page bg-black">
            <HomePoster
                gallerys={gallerys}
                topicDomRef={topicDomRef}
                renderLeftTopic={() => LeftImageTopic}
                renderRightTopic={() => RightImageTopic}
                smallerMonitor={column === 1}
                image={homeBg}
                slogan={slogan}
                logo={logo}
                styles={{
                    slogan: {
                        color: "#720000",
                    },
                }}
            />
            <Waterfall
                domRef={wrapRef}
                datasource={columnWrap}
                className="waterfall"
            />
            <Copyright />
            <PhotoViewer
                video={curVideo}
                small={curImg?.src}
                big={curImg?.getAttribute("data-src")}
                isShow={!!curImg}
                image={curImg?.parentNode}
                onHideEnd={onHideEnd}
                onHideStart={onHideStart}
                onShowStart={onShowStart}
                onPrev={prevHandle}
                onNext={nextHandle}
                showNext={curIndex < total - 1}
                showPrev={curIndex > 0}
            />
        </div>
    );
}
