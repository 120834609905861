/**
 * 生成指定范围内，特定长度的唯一数组
 * // 示例用法
const result = generateUniqueIntegers(1, 5, 5);
console.log(result); // 例如: [3, 8, 12, 15, 19]
 */
export function generateUniqueIntegers(min, max, groups) {
    if (max - min + 1 < groups) {
        throw new Error("范围内的数字不足以生成所需组数");
    }

    const uniqueNumbers = new Set();

    // 生成不重复的正整数
    while (uniqueNumbers.size < groups) {
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        uniqueNumbers.add(randomNum);
    }

    // 将 Set 转换为数组
    return Array.from(uniqueNumbers);
}
