import { useState } from "react";
import { Link } from "react-router-dom";

export default function FullScreenNav({
    list = [],
    nextLang = {},
    changeLanguage,
    active,
    activeName = "",
}) {
    const [isExtend, setExtend] = useState(false);
    const toggleMenu = () => {
        setExtend(!isExtend);
    };
    return (
        <div className="fullscreen-nav z-10">
            <button
                className={`fixed top-2 right-5 z-50 ${activeName}`}
                onClick={toggleMenu}
            >
                <span
                    className={`menu-icon ${isExtend ? "close-icon" : " "}`}
                ></span>
            </button>
            {/* Mask 层，增加动画效果 */}
            <div
                className={`mask-layer fixed top-0 left-0 w-full h-full bg-black transition-opacity z-20 duration-500 ${
                    isExtend ? "opacity-50" : "opacity-0 pointer-events-none"
                }`}
                onClick={toggleMenu}
            ></div>
            <div
                className={`menu-bg-layer fixed w-screen h-1/2 z-40 top-0 pt-10 bg-black flex transform transition-transform duration-500 ${
                    isExtend ? "translate-y-0" : "-translate-y-full opacity-0"
                }`}
            >
                <ul className="text-2xl w-full pl-20 font-bold flex-col">
                    {list.map(({ label, link }) => {
                        return (
                            <li
                                className={`mt-8 pr-5 font-medium text-white text-right ${activeName}`}
                            >
                                <Link
                                    onClick={() => {
                                        setExtend(false);
                                    }}
                                    to={link}
                                >
                                    {label}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
