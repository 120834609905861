import { createRef, useEffect } from "react";
import { throttle } from "lodash";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { createProductFullPath } from "../../../../shared/utils/createProductFullPath";

import "./style.scss";

function Fade({ gallerys = [] }) {
    const settings = {
        dots: false,
        fade: true,
        easing: "linear",
        infinite: true,
        autoplay: true,
        speed: 2000,
        lazyLoad: true,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
    };
    return (
        <div className="slider-container img-wrap box-border border-none cursor-pointer flex h-full overflow-hidden">
            <Slider {...settings}>
                {gallerys.map((gallery) => (
                    <img
                        key={gallery.id}
                        src={createProductFullPath(gallery.pictureKey)}
                        data-src={createProductFullPath(gallery.thumbnailUrl)}
                        alt={gallery.title}
                        className="outline-none overflow-hidden w-full h-full"
                    />
                ))}
            </Slider>
        </div>
    );
}

const TopicLayout = ({ topicDomRef, left, right, middle }) => {
    return (
        <div
            ref={topicDomRef}
            className="flex justify-between items-end w-full waterfall show-pictures"
        >
            <div className="flex-1 topic-container">{left()}</div>
            <div
                className="flex-none w-1/3 mt-1 mx-1 overflow-hidden topic-middle"
                style={{ aspectRatio: "1" }}
            >
                {middle()}
            </div>
            <div className="flex-1 topic-container">{right()}</div>
        </div>
    );
};

export default function HomePoster({
    gallerys = [],
    topicDomRef,
    renderLeftTopic = () => {},
    renderRightTopic = () => {},
    logo,
    image = "",
    slogan,
    smallerMonitor = false,
    name = "",
    styles = {
        slogan: {},
    },
}) {
    const sloganWrap = createRef();
    const onScroll = throttle((e) => {
        let st = document.getElementsByTagName("html")[0].scrollTop;
        if (st < window.innerHeight) {
            sloganWrap.current &&
                (sloganWrap.current.style.transform = `translateY(${
                    st / 20
                }px)`);
        }
    }, 100);
    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    const smallerMonitorClassName = smallerMonitor ? "is-smaller-monitor" : "";

    return (
        <div
            className={`relative home-poster w-screen flex flex-col justify-center items-center pt-28 ${smallerMonitorClassName}`}
        >
            <div
                className="flex justify-center"
                style={{ padding: "0 1rem 5rem" }}
                alt=""
            >
                <img
                    src={image}
                    alt=""
                    style={{
                        width: "80%",
                        height: "80%",
                    }}
                />
            </div>
            <TopicLayout
                topicDomRef={topicDomRef}
                className="waterfall show-pictures"
                left={renderLeftTopic}
                right={renderRightTopic}
                middle={() => {
                    return (
                        <Link
                            to="/gallery"
                            className="flex justify-center relative w-full h-full"
                        >
                            <Fade gallerys={gallerys} />
                        </Link>
                    );
                }}
            />
            <SloganWrap
                name={name}
                logo={logo}
                slogan={slogan}
                styles={styles}
            />
        </div>
    );
}

function SloganWrap({ logo, slogan, name, styles, ref }) {
    if (!logo && !slogan) {
        return null;
    }
    return (
        <div
            ref={ref}
            className="slogan-wrap relative top-1 w-full text-center transition duration-100"
        >
            {logo && (
                <img className="logo inline-block" src={logo} alt={name} />
            )}
            {slogan && (
                <p
                    className="slogan mt-10 text-2xl font-bold"
                    style={styles.slogan}
                >
                    {slogan}
                </p>
            )}
        </div>
    );
}
