import axios from "axios";
import Mock from "./mock";
import { API_URL } from "./env";
import { getCurLanguage } from "./lang";

const axiosInstance = axios.create({
    baseURL: API_URL,
    params: {
        i18nType: getCurLanguage(),
    },
    data: {
        i18nType: getCurLanguage(),
    },
});

export async function request({ type = "get", data = {}, url, headers = {} }) {
    let result = {};
    try {
        let { data: responseData, status } = await axiosInstance.request({
            method: type,
            url: url,
            params: type === "get" ? data : {},
            data: type !== "get" ? data : {},
            headers: headers,
        });
        responseData.success = responseData.code === 200;
        if (responseData && status) {
            result = responseData;
        } else {
            result = { success: false, code: -100, msg: "网络请求失败！" };
        }
        return result;
    } catch (error) {
        const { data = { success: false, code: -100 }, status = -100 } =
            error?.response || {};
        data.code = status;
        return data;
    }
}

Mock(axiosInstance);

export default axiosInstance;
