import React from "react";
import "./style.scss";

function Container({ className, style, children }) {
    return (
        <div className={className} style={style}>
            {children}
        </div>
    );
}

function Spinner() {
    return (
        <div className="spinner center">
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
        </div>
    );
}

export default function Loading({ show = false, className = "", style }) {
    if (!show) {
        return null;
    }

    if (className || style) {
        return (
            <Container className={className} style={style}>
                <Spinner></Spinner>
            </Container>
        );
    }

    return <Spinner />;
}
