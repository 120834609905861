import useNav from "./useNav";
// import TopNav from "./TopNav";
import FullScreenNav from "./FullScreenNav";

import "./style.scss";

export { useNav };

export default function Nav() {
    const { navLinks, nextLang, changeLanguage, isShowFull, active } = useNav();
    // 大屏使用特有的菜单，现在保持一致
    // const NavElement = isShowFull ? FullScreenNav : TopNav;
    const NavElement = FullScreenNav;

    return (
        <NavElement
            activeName={active.substring(1)}
            active={active}
            list={navLinks}
            nextLang={nextLang}
            changeLanguage={changeLanguage}
        />
    );
}
