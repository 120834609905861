import { getAppResource } from "../../../apis/document";

export async function fetchHomeBg() {
    const doc = await getAppResource({
        docKey: "home_bg",
        source: "home",
        type: "image",
    });
    return doc ? process.env.REACT_APP_OSS_URL + doc : doc;
}

export async function fetchLogo() {
    const doc = await getAppResource({
        docKey: "logo",
        source: "home",
        type: "image",
    });
    return doc ? process.env.REACT_APP_OSS_URL + doc : doc;
}

export async function fetchSlogan() {
    const doc = await getAppResource({
        docKey: "slogan",
        source: "home",
        type: "text",
    });
    return doc;
}

export async function fetchWaterfallColumn({ defaultColumnNumber = 3 }) {
    const columnRes = await getAppResource({
        docKey: "waterfall_column",
        source: "home",
        type: "number",
    });

    return Number(columnRes) || defaultColumnNumber;
}
